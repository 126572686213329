<template>
    <div class="container certificate pt20 pb20 pl20 pr20" v-if="infomation.project_name">
        <div class="cert-info-list bg-color">
            <div class="cert-info-item common-bottom flex flex-column x-left t-l">
                <span class="fs24 strong mb10 color000">项目名称：</span>
                <span class="title fs24">{{infomation.project_name}}</span>
            </div>
            <div class="cert-info-item flex x-between t-l">
                <div class="item-left common-bottom flex-column">
                    <span class="fs24 color000 strong mb10">会议时间：</span>
                    <span class="title fs24">{{infomation.meeting_date}}</span>
                </div>
                <div class="item-right common-bottom flex-column">
                    <span class="fs24 color000 strong mb10">会议地点：</span>
                    <span class="title fs24">{{infomation.area}}</span>
                </div>
            </div>
        </div>
        <div v-if="infomation.isCancelCert == 1">
            <div class="mt40 t-l" v-if="infomation.project_no">
                <div class="cert-info-list bg-color">
                    <div class="cert-info-item common-bottom flex x-left t-l">
                        <span class="fs24 mb10 ">
                            <span class="strong color000">项目编号：</span>
                            <span class="title">{{infomation.project_no}}</span>
                        </span>
                    </div>
                    <div class="cert-info-item flex x-between t-l">
                        <div class="item-lefti common-bottom ">
                            <span class="fs24">
                                <span class="strong color000">学员序号：</span>
                                <span class="title">{{infomation.student_no}}</span>
                            </span>
                        </div>
                        <div class="item-righti common-bottom ">
                            <span class="fs24">
                                <span class="strong color000">总序号：</span>
                                <span class="title">{{infomation.all_no}}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="cert-info-list bg-color mt40">
                    <div class="cert-info-item flex x-between t-l">
                        <div class="item-lefti common-bottom ">
                            <span class="fs24">
                                <span class="strong color000">姓名：</span>
                                <span class="title">{{infomation.name}}</span>
                            </span>
                        </div>
                        <div class="item-righti common-bottom ">
                            <span class="fs24">
                                <span class="strong color000">科室：</span>
                                <span class="title">{{infomation.office}}</span>
                            </span>
                        </div>
                    </div>
                    <div class="cert-info-item common-bottom flex x-left t-l">
                        <span class="fs24">
                            <span class="strong color000">工作单位：</span>
                            <span class="title">{{infomation.org}}</span>
                        </span>
                    </div>
                    <div class="cert-info-item flex x-between t-l">
                        <div class="item-lefti common-bottom ">
                            <span class="fs24">
                                <span class="strong color000">学分：</span>
                                <span class="title">{{infomation.score}}</span>
                            </span>
                        </div>
                        <div class="item-righti common-bottom ">
                            <span class="fs24">
                                <span class="strong color000">学时：</span>
                                <span class="title">{{infomation.study_time}}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <a :href="infomation.cert_pdf_url" class="x-center flex download-btn colorfff t-l fs16" @click="downloadPDF">
                    下载继续教育学分证书
                </a>
            </div>
            <div class="mt40 t-l" v-else>
                <div class="info fs24 color000 pt30 pb30 pl20 pr20 t-l mb40">
                    没有查询到您的学分信息，您可以按照以下提示进行核查
                </div>
                <div class="fs14 color000">{{infomation.project_name}}学分证书领取工作已经开始。继教项目编号：{{project_no}}。</div>
                <div class="fs14 color000 mt20">学分证书为电子学分证书，代表无需使用任何密码卡、IC卡或电子学分证书授予卡等实体卡片。</div>
                <div class="fs14 color000 mt40 strong">学分领取步骤如下：</div>
                <div class="mt10">
                    <div class="fs14 color000 mb20">1、登录中华医学会网站（www.cma.org.cn） ，点击“在线服务与互动”栏目下的“继续教育部相关服务”按钮；</div>
                    <img style="width:100%;height:100%;" src="https://files.sciconf.cn/upload/image/20210802/20210802183748_13430.png" alt="">
                </div>
                <div class="mt30">
                    <div class="fs14 color000 mb20">2、在“继续教育部相关服务”中点击“继教项目学员学分证书查询与打印”按钮；</div>
                    <img style="width:100%;height:100%;" src="https://files.sciconf.cn/upload/image/20210802/20210802183932_24168.png" alt="">
                </div>
                <div class="mt30">
                    <div class="fs14 color000 mb20">3、点击左侧“国家级”栏目下的“学分证书已发布项目”按钮；</div>
                    <img style="width:100%;height:100%;" src="https://files.sciconf.cn/upload/image/20210802/20210802184011_86028.png" alt="">
                </div>
                <div class="mt30">
                    <div class="fs14 color000 mb20">4、选择年度“2021”，在项目编号框中输入“项目编号”点击查询后，点击“学员证书查询”按钮；</div>
                    <img style="width:100%;height:100%;" src="https://files.sciconf.cn/upload/image/20210802/20210802184252_87649.png" alt="">
                </div>
                <div class="mt30">
                    <div class="fs14 color000 mb20">5、输入“姓名”后，点击“查询”按钮；出现个人信息界面，按照要求操作，然后点击“打印证书”按钮，即可自行打印学分证书。</div>
                    <img style="width:100%;height:100%;" src="https://files.sciconf.cn/upload/image/20210802/20210802184312_60076.png" alt="">
                </div>
            </div>
        </div>
        <div class="mt40" v-if="infomation.isCancelCert != 1">
            <img style="width:400px;" src="https://files.sciconf.cn/medcon/2021/08/20210802/2021080217512116923471058.png" alt="">
        </div>
    </div>
</template>

<script>
    import {login} from '@/utils/jumpPageMethods'
export default {
    name: 'MeetingCertificate',
    components: {
        
    },
    data() {
        return {
            meeting_id: this.$route.query.meeting_id,
            project_no: this.$route.query.project_no,
            search_year: this.$route.query.search_year,
            search_name:this.$route.query.realname,
            // 文章详情
            infomation: {},
        }
    },
    mounted() {
        if(!this.$store.getters.userInfo.user_id){
            login()
        }else{
            
            this.getInfoDetail()
        }
        
    },
    methods: {
        getInfoDetail() {
            this.request.get('CertSearch', {
                search_year: this.search_year,
                search_name: this.$store.getters.userInfo.RealName,
                project_no: this.project_no,
                meeting_id: this.meeting_id
            }).then(res => {
                this.infomation = res.data || {}
            })
        },
        downloadPDF(){
            let {cert_pdf_url, title} = this.infomation
            var downloadPath = cert_pdf_url
            var downloadLink = document.createElement('a')
            downloadLink.style.display = 'none'
            downloadLink.href = downloadPath
            downloadLink.download = title
            downloadLink.click()
            document.body.removeChild(downloadLink);
        },
    }
}
</script>
<style lang="less" scoped>
.certificate{
    .cert-info-list{
        .item-left{
            width: 50%;
        }
        .item-right{
            width: 40%;
        }
        .item-righti,.item-lefti{
            width: 45%;
        }
    }
    .download-btn{
        width: 300px;
        height: 40px;
        background: #0087FF;
        margin: 50px auto;
        border-radius: 20px;
        line-height: 40px;
        box-shadow: 0 9px 13px 0 rgba(86,163,255,0.39);
    }
    .common-bottom{
        border-bottom: 1px solid #EBEDF3;
        padding: 20px 0;
        .title{
            color: #6f6f6f;
        }
    }
    .info{
        background: #fff2f0;
        border: 1px solid #ffccc7;
    }
}
@media screen and (max-width:768px){
    .certificate{
        margin-top: -50px;
    }
}
</style>
